import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { withPreview } from '@prismicio/gatsby-source-prismic-graphql'
import get from 'lodash/get'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'
import { Link } from 'gatsby'

let newDate = new Date()
let year = newDate.getFullYear()

const footerQuery = graphql`
  query {
    prismic {
      allNavigations {
        edges {
          node {
            _meta {
              uid
              tags
            }
            body {
              ... on PRISMIC_NavigationBodySocial_network {
                fields {
                  social_network_url {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                  social_network
                }
              }
            }
            company_display_name
            company_address
            company_phone
            footer_links {
              link_label
              link {
                ... on PRISMIC_Page {
                  _meta {
                    uid
                    type
                    tags
                    lang
                  }
                }
                ... on PRISMIC__ExternalLink {
                  url
                }
                ... on PRISMIC__FileLink {
                  name
                  url
                  size
                }
              }
            }
          }
        }
      }
    }
  }
`

class Footer extends Component {

  render () {
    return (
      <StaticQuery
        query={`${footerQuery}`}
        render={withPreview(data => {
          const footerData = data.prismic.allNavigations.edges[0].node
          return (
            <footer role='banner' className='text-gray-700 bg-white relative'>
              <div className='max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8'>
                <div className='md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-8'>
                  <div className='md:col-span-1'>
                    <h5 className='mt-8 mb-4 text-blue-700'>{footerData.company_display_name}</h5>
                    {footerData.company_address && RichText.render(footerData.company_address, linkResolver)}
                    {footerData.company_phone && (
                      <div className='mt-4'>T: <a className='hover:text-blue-600' href={`tel:${footerData.company_phone}`}>{footerData.company_phone}</a></div>
                    )}
                  </div>

                  <div className='md:col-span-1'>
                    <h5 className='mt-8 mb-4 text-blue-700'>Useful Links</h5>
                    <div className='sf-root md:w-3/5' data-id='2990277' data-badge='light-partner'>
                      <ul>
                        {footerData.footer_links.map((slice, index) => {
                          var link = get(slice.link, 'url',(linkResolver(get(slice.link, '_meta',[]),[])))
                          return (
                            <li className='pb-4 pr-8 inline-block w-1/2 md:w-full md:block hover:text-blue-600' key={`footer-${index}`}>
                              {get(slice.link, 'url') ? (
                                <a href={link} target='_blank' rel='noopener noreferrer'>{get(slice, 'link_label')} <span className='sp-status'/></a>
                              ):(
                                <Link to={link}>{get(slice, 'link_label')}</Link>
                              )}
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className='md:col-span-1'>
                    <h5 className='mt-8 mb-4 text-blue-700'>Follow Us</h5>
                    <div className='flex mt-4 justify-center lg:justify-start'>
                      {footerData.body[0].fields.map((social, index) => {
                        return (
                          <a key={`social-${index}`} href={social.social_network_url.url} target='_blank' rel='noopener noreferrer' className='social-icon'>
                            <img src={require(`../images/${social.social_network.toLowerCase()}.png`)} alt={social.social_network} className={`social-${social.social_network} duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 mr-6 transform transition transiton w-12 md:w-10`} loading='lazy' />
                          </a>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className='mt-12 border-t border-gray-200 pt-8'>
                  <div className='text-base text-center'>
                    {footerData.company_display_name} <span className='text-blue-700'>&copy;</span> {year}<small className='block'> Company Number 09482917 <span className='font-bold text-blue-700'>/</span> VAT 352659676</small>
                  </div>
                </div>
              </div>
              <div ref={el => (this.instance = el)} />
            </footer>
          )
        }, footerQuery)}
      />
    )
  }
}

export default Footer
