import React, { PureComponent } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { withPreview } from '@prismicio/gatsby-source-prismic-graphql'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { linkResolver } from '../utils/linkResolver'

const headerQuery = graphql`
  {
    logo: file(relativePath: { eq: "logo_lgv-drivers-choice.png" }) {
      childImageSharp {
        fixed(width: 175) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    prismic {
      allNavigations {
        edges {
          node {
            company_address
            company_display_name
            company_phone
            nav {
              ... on PRISMIC_NavigationNavNav_item {
                primary {
                  label
                  link {
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
                fields {
                  sub_nav_link_label
                  sub_nav_link {
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
            }
            body {
              ... on PRISMIC_NavigationBodySocial_network {
                fields {
                  social_network
                  social_network_url {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Header = ({ siteTitle }) => {
  return (
    <React.Fragment>
      <RenderBody />
    </React.Fragment>
  )
}

class RenderBody extends PureComponent {
  constructor() {
    super()
    this.state = {
      mainNavExpanded: false,
      subNavExpanded: false,
    }
    this.toggleMainNav = this.toggleMainNav.bind(this);
    this.toggleSubNav = this.toggleSubNav.bind(this);
  }
  toggleMainNav() {
    this.setState({
      mainNavExpanded:!this.state.mainNavExpanded
    })
  }
  toggleSubNav(id) {
    if (id === this.state.subNavExpandedId) {
      this.setState({
        subNavExpandedId:false
      })
    } else {
      this.setState({
        subNavExpandedId:id
      })
    }
  }

  render() {
  return (
    <StaticQuery
      query={`${headerQuery}`}
      render={withPreview(data => {
        const headerData = data.prismic.allNavigations.edges[0].node
        return (
          <header role='banner' className='bg-blue-500'>
            <nav className='max-w-screen-xl mx-auto flex items-center justify-between flex-wrap p-2 relative'>
              <Link to='/' className='logo pt-4 z-20 relative'>
                <Img fixed={data.logo.childImageSharp.fixed} alt={`${headerData.company_display_name} logo`} />
              </Link>
              <button type='button' aria-expanded={this.state.mainNavExpanded} onClick={ this.toggleMainNav } onKeyDown={ this.toggleMainNav }
              className='ml-2 rounded-md flex items-center justify-center px-4 py-2 text-base font-bold border-2 text-blue-700 bg-white border-white relative block z-20 lg:hidden'>
                Menu
              </button>
              <div className='fixed z-10 inset-0 w-full h-screen flex-grow lg:relative lg:inset-auto lg:h-auto lg:flex lg:items-center lg:w-auto lg:justify-end' hidden={!this.state.mainNavExpanded}>
                <div className='pt-32 lg:pt-0 absolute inset-0 lg:relative lg:text-left lg:text-xl'>
                  <div className='bg-black opacity-75 absolute inset-0 lg:opacity-100 lg:bg-transparent'></div>
                  <div className='lg:flex justify-end z-20 relative px-3'>
                    <a className='cursor-pointer text-3xl lg:text-xl font-semibold lg:font-black flex flex-row justify-start block px-3 py-2 mr-4 text-white hover:text-blue-200 focus:text-blue-100 transition duration-150 ease-in-out' href={`tel:${headerData.company_phone}`}>
                      <svg className='duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 mr-3 transform transition transiton w-6' viewBox="0 0 463.009 463.009">
                        <g>
                          <path fill='currentColor' d="m462.692,381.085c-1.472-11.126-7.895-20.719-17.62-26.318l-114.226-65.767c-13.99-8.055-31.738-5.71-43.157,5.708l-22.499,22.499c-5.987,5.988-15.459,6.518-22.028,1.231-17.737-14.272-35.201-29.979-51.906-46.685-16.705-16.705-32.412-34.168-46.685-51.906-5.287-6.57-4.758-16.041 1.231-22.029l22.498-22.499c11.418-11.417 13.766-29.163 5.709-43.156l-65.767-114.226c-5.6-9.726-15.192-16.148-26.318-17.62-11.127-1.475-22.06,2.236-29.996,10.172l-33.901,33.902c-23.661,23.662-24.041,66.944-1.07,121.875 22.088,52.818 63.308,110.962 116.065,163.721 52.759,52.758 110.903,93.978 163.722,116.066 27.039,11.307 51.253,16.957 71.697,16.956 21.088,0 38.163-6.013 50.178-18.027l33.901-33.902c7.935-7.936 11.643-18.869 10.172-29.995zm-139.33-79.086l114.226,65.767c5.649,3.252 9.379,8.824 10.233,15.286 0.718,5.423-0.691,10.763-3.885,15.066l-151.805-86.638 6.165-6.165c6.631-6.631 16.941-7.994 25.066-3.316zm-243.406-286.811c6.463,0.855 12.034,4.585 15.286,10.234l65.767,114.226c4.68,8.127 3.316,18.435-3.315,25.065l-5.663,5.663-87.114-151.303c3.561-2.637 7.82-4.069 12.26-4.069 0.921-1.77636e-15 1.85,0.061 2.779,0.184zm328.055,419.187c-18.798,18.798-57.244,18.01-105.48-2.162-51.06-21.352-107.491-61.424-158.901-112.833-51.41-51.41-91.482-107.842-112.834-158.901-20.173-48.237-20.96-86.683-2.162-105.482l25.167-25.168 87.245,151.532-5.851,5.851c-11.415,11.416-12.409,29.488-2.311,42.04 14.609,18.156 30.68,36.024 47.764,53.108 17.086,17.085 34.954,33.156 53.109,47.765 12.55,10.098 30.622,9.105 42.04-2.312l5.338-5.338 152.016,86.759-25.14,25.141z"/>
                        </g>
                      </svg>
                      {headerData.company_phone}
                    </a>
                    <Link className='cursor-pointer text-3xl lg:text-xl font-semibold lg:font-black flex flex-row justify-start block px-3 py-2 text-white hover:text-blue-200 focus:text-blue-100 transition duration-150 ease-in-out' to='/contact'>
                      <svg className='duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 mr-3 transform transition transiton w-6' viewBox="0 0 512.022 512.022">
                        <g>
                          <path fill='currentColor' d="m158.526 176.135c.475 25.361 10.737 49.25 28.897 67.265 18.159 18.014 42.129 28.087 67.495 28.365 11.825.131 23.461-1.844 34.484-5.866 3.892-1.418 5.896-5.723 4.478-9.614s-5.723-5.898-9.614-4.478c-9.326 3.399-19.117 5.08-29.184 4.958-44.146-.483-80.733-36.78-81.561-80.971-.599-22.455 8.518-44.538 25.01-60.587 16.498-16.053 38.831-24.555 61.324-23.35 42.998 1.948 77.534 37.205 78.625 80.266.255 10.038-1.268 19.879-4.524 29.247-2.163 6.221-8.097 10.4-14.768 10.4-.001 0-.002 0-.002 0-8.645 0-15.678-7.033-15.678-15.679v-61.821c0-4.143-3.357-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v5.406c-8.504-7.994-19.936-12.906-32.5-12.906-26.191 0-47.5 21.309-47.5 47.5s21.309 47.5 47.5 47.5c13.563 0 25.807-5.724 34.471-14.871 4.381 11.599 15.593 19.872 28.706 19.872h.003c13.05 0 24.679-8.229 28.936-20.475 3.853-11.079 5.652-22.704 5.353-34.554-1.29-50.895-42.115-92.566-92.877-94.866-26.598-1.438-53.039 8.615-72.528 27.581-19.486 18.96-30.254 45.108-29.546 71.678zm97.483 30.635c-17.921 0-32.5-14.579-32.5-32.5s14.579-32.5 32.5-32.5 32.5 14.579 32.5 32.5-14.579 32.5-32.5 32.5z"/>
                          <path fill='currentColor' d="m509.119 199.12-210.28-164.308c-24.322-19.003-58.375-19.104-82.809-.242l-213.094 164.51c-1.828 1.399-2.939 3.648-2.935 5.95v66c0 4.143 3.357 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-53.22l188.602 105.354-188.602 145.601v-162.735c0-4.143-3.357-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v178c-.066 4.026 3.471 7.57 7.5 7.5h497c4.001.054 7.584-3.477 7.5-7.5v-278.114c.18-2.574-.803-5.206-2.882-6.796zm-479.63 277.41 195.708-151.087c19.004-14.67 45.489-14.594 64.406.189l3.612 2.822c.003.002 94.023 73.466 94.03 73.472 3.266 2.55 7.977 1.973 10.528-1.292 2.55-3.265 1.972-7.978-1.292-10.528l-85.229-66.595 46.113-25.979c3.608-2.033 4.886-6.606 2.853-10.216-2.032-3.609-6.604-4.883-10.216-2.853l-51.562 29.049c-24.131-18.571-57.647-18.694-81.911-.31l-195.45-109.18 204.119-157.58c19.004-14.67 45.489-14.593 64.406.189l202.162 157.964-112.14 63.177c-3.608 2.033-4.886 6.606-2.853 10.216 2.045 3.643 6.673 4.866 10.216 2.853l110.013-61.979v249.788l-73.729-57.61c-3.264-2.549-7.977-1.973-10.528 1.292-2.55 3.265-1.972 7.978 1.292 10.528l68.685 53.669h-453.233z"/>
                        </g>
                      </svg>
                      Email Us
                    </Link>
                  </div>
                  <ul className='lg:flex flex-col justify-top lg:flex-row font-semibold relative'>
                   {headerData.nav.map((nav, index) => {
                     var link = get(nav.primary.link, 'url',(linkResolver(get(nav.primary.link, '_meta',[]),[])))
                     return (
                       <React.Fragment key={`nav-${index}`}>
                         {nav.fields.length === 0 ? (
                            <li className='my-2 p-2 block lg:my-0 lg:mr-4 lg:p-0' key={`nav-${index}`}>
                              <Link to={link} className='text-3xl lg:text-xl flex w-full flex-row justify-start lg:mt-1 block px-3 lg:py-2 font-semibold text-white hover:text-blue-200 focus:text-blue-100 transition duration-150 ease-in-out' activeClassName='active' onClick={ this.toggleNav }>{nav.primary.label}</Link>
                            </li>
                         ):(
                           <li className='my-2 p-2 block lg:my-0 lg:mr-4 lg:p-0'>
                             <button type='button' className='dropdown text-3xl lg:text-xl flex w-full flex-row justify-start lg:mt-1 block px-3 lg:py-2 font-semibold text-white hover:text-blue-200 focus:text-blue-100 transition duration-150 ease-in-out' aria-haspopup='true' aria-expanded={this.state.subNavExpanded} onClick={() => this.toggleSubNav(`${nav.primary.label}`)} onKeyDown={() => this.toggleSubNav(`${nav.primary.label}`)}>{nav.primary.label} <svg xmlns='http://www.w3.org/2000/svg' className='mt-3 lg:mt-1 -mr-2 lg:-mr-0' height='1em' width='1em'><path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z' fill='currentColor'/><path d='M0 0h24v24H0z' fill='none'/></svg></button>
                             <ul className={`lg:w-3/4 lg:absolute lg:bg-white lg:overflow-hidden rounded-lg lg:shadow-lg transform transition duration-150 ease-in-out ${this.state.subNavExpandedId === nav.primary.label ? 'block': 'hidden'}`}>
                               {nav.fields.map((subNav, index) => {
                                 var subLink = get(subNav.sub_nav_link, 'url',(linkResolver(get(subNav.sub_nav_link, '_meta',[]),[])))
                                 return (
                                   <React.Fragment key={`subNav-${index}`}>
                                    <li className='lg:hover:bg-blue-100'>
                                      <Link to={subLink} className='px-10 py-5 lg:p-4 block focus:outline-none text-xl lg:text-lg text-white lg:text-blue-500 lg:hover:text-blue-600 font-semibold focus:text-blue-600 ' activeClassName='active' onClick={() => this.toggleSubNav(`${nav.primary.label}`)}>
                                        <div className='block '>{subNav.sub_nav_link_label}</div>
                                      </Link>
                                    </li>
                                   </React.Fragment>
                                 )
                               })}
                             </ul>
                           </li>
                           )}
                         </React.Fragment>
                       )
                     })}
                   </ul>
                </div>
              </div>
            </nav>
          </header>
        )
      }, headerQuery)}
    />
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
