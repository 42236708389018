import React from 'react'


const Affiliates = () => {
  return (
    <div className='px-6 py-8 sm:py-12 bg-white border-b relative'>
      <h2 className="text-center mb-6 text-blue-700">Trusted Partners</h2>
      <div className='max-w-5xl mx-auto flex flex-row flex-wrap justify-around flex-1'>
        <img src={require(`../images/Logo Jack Richards.png`)} alt='Jack Richards Logo' className='duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 mr-6 transform transition transiton w-32 md:w-auto md:h-32' loading='lazy' />
        <img src={require(`../images/Logo Palletways.png`)} alt='Palletways Logo' className='duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 mr-6 transform transition transiton w-32 md:w-auto md:h-32' loading='lazy' />
        <img src={require(`../images/Logo Yelovest.png`)} alt='Yelovest Logo' className='duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 mr-6 transform transition transiton w-32 md:w-auto md:h-32' loading='lazy' />
        <img src={require(`../images/Logo globetrotter.jpg`)} alt='Globetrotter Logo' className='duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 mr-6 transform transition transiton w-32 md:w-auto md:h-32' loading='lazy' />
      </div>
    </div>
  )
}

export default Affiliates
