import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import CookieConsent from 'react-cookie-consent';
import Header from './header'
import Affiliates from './affiliates'
import Footer from './footer'
import '../styles/default.scss'

const siteQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
  }
`


const Layout = props => {
  return (
    <StaticQuery
      query={`${siteQuery}`}
      render={data => (
        <div className={`antialiased text-gray-800 bg-blue-100`}>
          <Header siteTitle={data.site.siteMetadata.title} />
          <main role='main' id='main'>{props.children}</main>
          <Affiliates />
          <Footer />
          <div id='fb-root'></div>
          <CookieConsent
            location='bottom'
            buttonText='Accept'
            declineButtonText='Decline'
            disableStyles
            buttonClasses='ml-2 rounded-md text-blue-700 bg-white hover:bg-blue-100 hover:text-blue-800 shadow px-4 py-2 border border-transparent font-bold transform duration-300 ease-in-out transition'
            declineButtonClasses='ml-2 rounded-md text-white bg-blue-700 hover:bg-blue-500 shadow px-4 py-2 border border-white font-bold transform duration-300 ease-in-out transition'
            containerClasses='z-30 fixed inset-x-0 text-white bg-blue-700 p-3 sm:flex justify-center font-semibold'
            contentClasses='py-2 flex-1 '
            enableDeclineButton
            cookieName='gatsby-gdpr-google-analytics'>
            We use cookies to collect information about how you use {data.site.siteMetadata.title}. See our <Link to='/privacy-policy' className='underline'>Privacy Policy</Link> to see how we use this information.
            </CookieConsent>
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
}

export default Layout
